import { IGovernanceHierarchy } from '@shared/governance/interfaces/governance-hierarchy.interface';
import { IntendedSetupInterface } from '@shared/governance/interfaces/intended-setup.interface';
import { GovernanceIntendedSetupEl } from '@shared/governance/models/governance-setup.model';
import { GovernanceHierarchy } from '@shared/governance/types/governance-hierarchy.type';
import { QaCheckType } from '@shared/qa-checklists/types/qa-check-type.type';
import { QaResultCheckStatusType } from '@shared/qa-checklists/types/qa-result-check-status.type';
import { QaResultStatusType } from '@shared/qa-checklists/types/qa-result-status.type';

export class QaResultCheck implements IGovernanceHierarchy {
  qa_result_id: number;
  check_id: number;
  check_name: string;
  platform_level: string;
  entity_id: string;
  entity_name: string;
  check_type: QaCheckType;
  check_status: QaResultCheckStatusType;
  check_result_status: QaResultStatusType;
  check_results: (IntendedSetupInterface | GovernanceIntendedSetupEl)[];
  hyperlink: string;
  manager_id: string;
  hierarchy: GovernanceHierarchy;
  customer_id: string;
  mcc: string;
}
